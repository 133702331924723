import React from 'react'
import Part1 from "./components/Part1.jsx";
import Part2 from './components/Part2.jsx';
import Part3 from './components/Part3.jsx';

const App = () => {
  return (
    <>
      <Part1 />
      <Part2 />
    </>
  )
}

export default App
